/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const DayHeaderStyled = styled.span`
    width: 40px;
    height: 40px;

    padding: ${({ theme }) => theme.spacing[1]};
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: ${({ theme }) => theme.fontWeights.bold};

    user-select: none;

    color: ${({ theme }) => theme.palette.grey[500]};

    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey[300]}`};

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        width: auto;
    }
`;

export { DayHeaderStyled };
