import React, { PureComponent } from 'react';

// Relative imports
import { TdStyled, DateStyled } from './Day.style';
import { DayStatus } from './Day.constants';
import { formatDate } from '../../../../utils/date';

class Day extends PureComponent {
    // TODO: work out if we can customise shouldComponentUpdate to prevent unnecessary re-renders
    static defaultProps = {
        dayStatus: DayStatus.UNSELECTED,
        isOverflow: false,
    };

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.onClick = this.onClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onHover = this.onHover.bind(this);
        this.checkFocus = this.checkFocus.bind(this);
    }

    componentDidMount() {
        this.checkFocus();
    }

    componentDidUpdate() {
        this.checkFocus();
    }

    onKeyDown(e) {
        const { date } = this.props;
        const { key } = e;

        if (key === 'Enter' || key === ' ') {
            e.preventDefault();
            this.onClick(date);
        }
    }

    onClick() {
        const { date, onDayClick, dayStatus, isToday, isOverflow } = this.props;

        const canSelect =
            !isOverflow &&
            (isToday ||
                dayStatus === DayStatus.HIGHLIGHTED ||
                dayStatus === DayStatus.SELECTED ||
                dayStatus === DayStatus.SELECTED_FROM ||
                dayStatus === DayStatus.SELECTED_TO ||
                dayStatus === DayStatus.SELECTED_SOLO ||
                dayStatus === DayStatus.UNSELECTED);

        if (canSelect) {
            onDayClick(date);
        }
    }

    onHover() {
        // We don't want to handle hover on touch devices because iOS < 12 will
        // consider the first tap to be the hover which means that a "click" takes two taps.
        // We could remove this once we stop supporting iOS11 but it would be worth checking Android too
        if ('ontouchstart' in window) return;

        const { date, onDayHover, dayStatus, isToday, isOverflow } = this.props;

        const canSelect =
            !isOverflow &&
            (isToday ||
                dayStatus === DayStatus.HIGHLIGHTED ||
                dayStatus === DayStatus.SELECTED ||
                dayStatus === DayStatus.SELECTED_FROM ||
                dayStatus === DayStatus.SELECTED_TO ||
                dayStatus === DayStatus.UNSELECTED);

        if (canSelect) {
            onDayHover(date);
        }
    }

    checkFocus() {
        const { isFocused } = this.props;
        if (isFocused) {
            this.ref.current.focus();
        }
    }

    render() {
        const { date, dayStatus, tabIndex, faded, isToday, allowHovering, className, isOverflow } =
            this.props;

        // We only need interaction with normal days. Overflow days should be hidden
        const otherProps = isOverflow
            ? {}
            : {
                  dayStatus,
                  tabIndex,
                  isToday,
                  allowHovering,
                  onMouseOver: this.onHover,
                  onClick: this.onClick,
                  onKeyDown: this.onKeyDown,
              };

        return (
            <TdStyled className={className} ref={this.ref} height="40" width="40" {...otherProps}>
                {!isOverflow && (
                    <DateStyled aria-label={formatDate(date, 'dd MMM yyyy EEEE')} faded={faded}>
                        {formatDate(date, 'dd')}
                    </DateStyled>
                )}
            </TdStyled>
        );
    }
}

export { Day as default, DayStatus };
