import React from 'react';
import { setDay } from 'date-fns';
import { DayHeaderStyled } from './DayHeaders.style';
import { formatDate } from '../../../../utils/date';

const DayHeaders = ({ tag }) => {
    const TagName = tag;

    const renderDayHeaders = () => {
        const headers = [];
        const date = new Date();
        // Start at i = 1 because our weeks start on a Monday
        for (let dayIndex = 1; dayIndex < 8; dayIndex += 1) {
            const newDate = setDay(date, dayIndex);
            headers.push(
                <TagName key={newDate.getTime()} width="40" style={{ padding: 0 }}>
                    <DayHeaderStyled>{formatDate(newDate, 'EEEEE')}</DayHeaderStyled>
                </TagName>
            );
        }
        return headers;
    };

    return <>{renderDayHeaders()}</>;
};

DayHeaders.defaultProps = {
    tag: 'span',
};
export default DayHeaders;
