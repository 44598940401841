import React, { PureComponent } from 'react';

// Relative imports
import { formatDate as format } from '../../../../utils/date';
import Month from '../Month';

import { MonthHeader, CalendarHeader, TableStyled } from './Calendar.style';
import DayHeaders from '../DayHeaders';

class Calendar extends PureComponent {
    // TODO: work out if we can possibly avoid re-rendering this component to improve selection performance
    render() {
        const {
            allowHovering,
            allowSelecting,
            focusedDate,
            highlightedRange,
            mobileHeaders,
            month,
            onDayClick,
            onDayHover,
            renderDayHeaders,
            selectedRange,
            unavailableDates,
            availableDates,
            showAvailability,
            ...props
        } = this.props;

        return (
            <TableStyled {...props}>
                <MonthHeader>{format(month, 'MMMM yyyy')}</MonthHeader>
                {renderDayHeaders && (
                    <thead>
                        <CalendarHeader mobileHeaders={mobileHeaders}>
                            <DayHeaders tag="th" />
                        </CalendarHeader>
                    </thead>
                )}
                <Month
                    focusedDate={focusedDate}
                    onDayClick={onDayClick}
                    onDayHover={onDayHover}
                    month={month}
                    unavailableDates={unavailableDates}
                    availableDates={availableDates}
                    selectedRange={selectedRange}
                    highlightedRange={highlightedRange}
                    allowHovering={allowHovering}
                    allowSelecting={allowSelecting}
                    showAvailability={showAvailability}
                />
            </TableStyled>
        );
    }
}

Calendar.defaultProps = {
    month: new Date(),
    unavailableDates: [],
    mobileHeaders: false,
};

export { Calendar as default, TableStyled as CalendarWrapperStyled };
