import React, { Component } from 'react';
import {
    addMonths,
    areIntervalsOverlapping,
    isBefore,
    addWeeks,
    subWeeks,
    subDays,
    addDays,
    subMonths,
    startOfMonth,
    isSameDay,
    lastDayOfMonth,
    isWithinInterval,
} from 'date-fns';
import { parseDate } from 'api/helpers/format/date';
import { IconChevronLeft, IconChevronRight } from 'components/Icon';
import ZIndexManager from 'components/ZIndexManager';
import {
    WrapperStyled,
    BackButtonStyled,
    ForwardButtonStyled,
    CalendarStyled,
    KeyContainerStyled,
    KeyItemStyled,
} from './Datepicker.style';
import DayHeaders from './components/DayHeaders';

class Datepicker extends Component {
    static DayHeaders = (props) => <DayHeaders {...props} />;

    static defaultProps = {
        allowHovering: true,
        allowSelecting: true,
        showAvailability: false,
        allowSelectingUnavailable: false,
        defaultMonth: new Date(),
        mobilePagination: false,
        numberOfMonths: 2,
        onChange: () => {},
        onUnavailableDateRangeSelected: () => {},
        pagination: true,
        renderDayHeaders: true,
        selectedRange: {
            dateFrom: null,
            dateTo: null,
        },
        unavailableDates: [],
        availableDates: [],
        showKey: false,
    };

    constructor(props) {
        super(props);
        this.onDayClick = this.onDayClick.bind(this);
        this.onDayHover = this.onDayHover.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
        this.setDateTo = this.setDateTo.bind(this);
        this.setDateFrom = this.setDateFrom.bind(this);
    }

    state = {
        selectedRange: this.props.selectedRange
            ? this.props.selectedRange
            : { dateFrom: null, dateTo: null },
        highlightedRange: {
            dateFrom: null,
            dateTo: null,
        },
        focusedDate: new Date(),
        prevFocusedDate: null,
        currentMonth: this.props.defaultMonth,
    };

    componentDidUpdate({ selectedRange: { dateFrom: prevDateFrom, dateTo: prevDateTo } }) {
        const {
            selectedRange: { dateFrom, dateTo },
        } = this.props;

        const hasUpdated = prevDateFrom !== dateFrom || prevDateTo !== dateTo;

        if (hasUpdated) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                selectedRange: this.props.selectedRange,
            });
        }
    }

    onDayClick(date) {
        const {
            allowSelecting,
            unavailableDates,
            allowSelectingUnavailable,
            onUnavailableDateRangeSelected,
        } = this.props;
        if (!allowSelecting) return;

        const { dateFrom, dateTo } = this.state.selectedRange;
        const unavailableRange = {
            dateFrom: null,
            dateTo: null,
        };
        const dateFromParsed = parseDate(dateFrom);

        // Circumstances under which we only set a dateFrom and nullify the dateTo:
        // 1. We already have both a dateFrom AND a dateTo set
        // 2. We don't have a dateFrom
        // 3. The selected date is before the dateFrom
        // 4. If an unavailable range and the selected range would overlap
        if (
            /* 1. */ (dateFrom && dateTo) ||
            /* 2. */ !dateFrom ||
            /* 3. */ isBefore(date, dateFromParsed)
        ) {
            this.setDateFrom(date);
        }
        // We handle 4. in a separate `if` so that we can call a method on props
        else if (
            /* 4. */ dateFrom &&
            unavailableDates.some((dateRange) => {
                unavailableRange.dateFrom = dateRange.dateFrom;
                unavailableRange.dateTo = dateRange.dateTo;
                const dateRangeFromParsed = parseDate(dateRange.dateFrom);
                const dateRangeToParsed = parseDate(dateRange.dateTo);

                return areIntervalsOverlapping(
                    {
                        start: dateRangeFromParsed,
                        end: dateRangeToParsed,
                    },
                    {
                        start: dateFromParsed,
                        end: date,
                    }
                );
            })
        ) {
            if (allowSelectingUnavailable) {
                this.setDateTo(date);
            } else {
                this.setDateFrom(date);
            }

            onUnavailableDateRangeSelected({
                selectedRange: { dateFrom, dateTo: date },
                unavailableRange,
            });
        }
        // Circumstances under which we set a dateTo and maintain the existing dateFrom:
        // 1. Any other one
        else {
            this.setDateTo(date);
        }
    }

    onDayHover(date) {
        const { allowHovering } = this.props;

        if (!allowHovering) return;

        const { dateTo } = this.state.selectedRange;
        const { dateFrom } = this.state.highlightedRange;

        if (dateFrom) {
            // Cannot set a dateTo before our dateFrom
            if (isBefore(date, dateFrom)) {
                this.setState((state) => ({
                    highlightedRange: {
                        dateFrom: state.selectedRange.dateFrom,
                        dateTo: null,
                    },
                }));
            } else if (dateFrom && dateTo) {
                this.setState({
                    highlightedRange: {
                        dateFrom: null,
                        dateTo: null,
                    },
                });
            } else {
                this.setState((state) => ({
                    highlightedRange: {
                        ...state.highlightedRange,
                        dateTo: date,
                    },
                }));
            }
        }
    }

    onBackClick() {
        this.setState((state) => ({
            currentMonth: subMonths(state.currentMonth, 1),
        }));
    }

    onForwardClick = () => {
        this.setState((state) => ({
            currentMonth: addMonths(state.currentMonth, 1),
        }));
    };

    onKeyDown(event) {
        event.stopPropagation();
        const { focusedDate, currentMonth } = this.state;
        const secondMonth = addMonths(currentMonth, 1);
        let newFocusedDate;

        const onFirstDay = isSameDay(focusedDate, startOfMonth(currentMonth));
        const onLastDay = isSameDay(focusedDate, lastDayOfMonth(secondMonth));
        const onFirstWeek = isWithinInterval(focusedDate, {
            start: startOfMonth(currentMonth),
            end: addDays(startOfMonth(currentMonth), 6),
        });
        const onLastWeek = isWithinInterval(focusedDate, {
            start: subDays(lastDayOfMonth(secondMonth), 6),
            end: lastDayOfMonth(secondMonth),
        });

        switch (event.key) {
            case 'ArrowDown':
                event.preventDefault();
                newFocusedDate = addWeeks(focusedDate, 1);

                if (onLastWeek || onLastDay) {
                    this.setState({
                        currentMonth: addMonths(currentMonth, 1),
                    });
                }

                this.setState({
                    focusedDate: newFocusedDate,
                });

                break;
            case 'ArrowUp':
                event.preventDefault();
                newFocusedDate = subWeeks(focusedDate, 1);

                if (onFirstWeek || onFirstDay) {
                    this.setState({
                        currentMonth: subMonths(currentMonth, 1),
                    });
                }

                this.setState({
                    focusedDate: newFocusedDate,
                });
                break;
            case 'ArrowLeft':
                event.preventDefault();
                newFocusedDate = subDays(focusedDate, 1);

                if (onFirstDay) {
                    this.setState({
                        currentMonth: subMonths(currentMonth, 1),
                    });
                }

                this.setState({
                    focusedDate: newFocusedDate,
                });
                break;
            case 'ArrowRight':
                event.preventDefault();
                newFocusedDate = addDays(focusedDate, 1);

                if (onLastDay) {
                    this.setState({
                        currentMonth: addMonths(currentMonth, 1),
                    });
                }

                this.setState({
                    focusedDate: newFocusedDate,
                });
                break;
            default:
                break;
        }
    }

    setDateFrom(date) {
        const selectedRange = {
            dateFrom: date,
            dateTo: null,
        };

        this.setState(
            {
                selectedRange,
                highlightedRange: {
                    dateFrom: date,
                    dateTo: null,
                },
                focusedDate: date,
            },
            () => {
                this.props.onChange(selectedRange);
            }
        );
    }

    setDateTo(date) {
        const { dateFrom } = this.state.selectedRange;

        this.setState(
            {
                selectedRange: {
                    dateFrom,
                    dateTo: date,
                },
                focusedDate: date,
            },
            () => {
                this.props.onChange({
                    dateFrom,
                    dateTo: date,
                });
            }
        );
    }

    renderCalendars = () => {
        const {
            allowHovering,
            allowSelecting,
            unavailableDates,
            availableDates,
            numberOfMonths,
            horizontal,
            renderDayHeaders,
            mobileHeaders,
            showAvailability,
        } = this.props;
        const { selectedRange, highlightedRange, focusedDate, currentMonth } = this.state;
        const calendarArray = [];
        for (let monthIndex = 0; monthIndex < numberOfMonths; monthIndex += 1) {
            const month = addMonths(currentMonth, monthIndex);

            calendarArray.push(
                <CalendarStyled
                    mobileHeaders={mobileHeaders}
                    horizontal={horizontal}
                    key={month.getTime()}
                    focusedDate={focusedDate}
                    month={month}
                    onDayClick={this.onDayClick}
                    onDayHover={this.onDayHover}
                    selectedRange={selectedRange}
                    highlightedRange={highlightedRange}
                    unavailableDates={unavailableDates}
                    availableDates={availableDates}
                    renderDayHeaders={renderDayHeaders}
                    allowHovering={allowHovering}
                    allowSelecting={allowSelecting}
                    showAvailability={showAvailability}
                />
            );
        }
        return calendarArray;
    };

    render() {
        const { horizontal, mobilePagination, translate, className, showKey } = this.props;
        return (
            <>
                <WrapperStyled
                    onKeyDown={this.onKeyDown}
                    horizontal={horizontal}
                    className={className}
                >
                    <ZIndexManager>
                        <>
                            <BackButtonStyled
                                mobilePagination={mobilePagination}
                                iconProps={{
                                    title: translate('components_Datepicker_prevMonth'),
                                    id: 'datepicker-previous-month',
                                    size: 'medium',
                                }}
                                icon={IconChevronLeft}
                                onClick={this.onBackClick}
                            />
                            <ForwardButtonStyled
                                mobilePagination={mobilePagination}
                                iconProps={{
                                    title: translate('components_Datepicker_nextMonth'),
                                    id: 'datepicker-next-month',
                                    size: 'medium',
                                }}
                                icon={IconChevronRight}
                                onClick={this.onForwardClick}
                            />
                        </>
                    </ZIndexManager>
                    {this.renderCalendars()}
                </WrapperStyled>

                {showKey && (
                    <KeyContainerStyled>
                        <KeyItemStyled>
                            {translate('components_Datepicker_key_availability')}
                        </KeyItemStyled>
                    </KeyContainerStyled>
                )}
            </>
        );
    }
}

export default Datepicker;
