import styled, { css } from 'styled-components';
import { DayStatus } from './Day.constants';

// purple dot under the number
const todayStyle = css`
    &:after {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);

        width: 6px;
        height: 6px;

        border-radius: 100%;

        content: '';
        background-color: ${({ theme }) => theme.palette.accent};
    }
`;

// crossed out number
const unavailableStyle = css`
    &:before {
        content: ' ';
        position: absolute;
        width: 24px;
        height: 1px;

        left: ${({ theme }) => theme.spacing[1]};
        top: 20px;
        right: 0;

        transform: rotate(-45deg);
        background-color: ${({ theme }) => theme.palette.black};
    }
    &:hover {
        cursor: not-allowed;
    }
`;

const unavailableFadedStyle = css`
    ${unavailableStyle};
    color: ${({ theme }) => theme.palette.grey[300]};
    &:before {
        background-color: ${({ theme }) => theme.palette.grey[300]};
    }
`;

const availableStyle = css`
    background-color: ${({ theme }) => theme.palette.primarySoft};
`;

const fadedStyle = css`
    color: ${({ theme }) => theme.palette.grey[300]};
    cursor: not-allowed;
`;

const selectedStyle = css`
    background-color: ${({ theme }) => theme.palette.accent};
    color: ${({ theme }) => theme.palette.white};
`;

const focusedStyle = css`
    border: solid 1px ${({ theme }) => theme.palette.borderDark};
`;

const highlightedStyle = css`
    background-color: ${({ theme }) => theme.palette.accentLight};
`;

const dayStyles = {
    faded: fadedStyle,
    highlighted: highlightedStyle,
    'selected-from': css`
        ${selectedStyle};
        border-top-left-radius: 100%;
        border-bottom-left-radius: 100%;
    `,
    'selected-solo': css`
        ${selectedStyle};
        border-radius: 100%;
    `,
    'selected-to': css`
        ${selectedStyle};
        border-top-right-radius: 100%;
        border-bottom-right-radius: 100%;
    `,
    available: css`
        ${availableStyle};
    `,
    'available-from': css`
        ${availableStyle};
        border-top-left-radius: 100%;
        border-bottom-left-radius: 100%;
    `,
    'available-to': css`
        ${availableStyle};
        border-top-right-radius: 100%;
        border-bottom-right-radius: 100%;
    `,
    selected: selectedStyle,
    'unavailable-faded': unavailableFadedStyle,
    unavailable: unavailableStyle,
};

const hoverStyles = css`
    @media (hover: hover) {
        &:hover {
            cursor: pointer;
            background-color: ${({ theme }) => theme.palette.hoverGrey};
            color: ${({ theme }) => theme.palette.text};
        }
    }
`;

const TdStyled = styled.td`
    position: relative;
    text-align: center;

    padding: ${({ theme }) => theme.spacing[1]};
    ${({ allowHovering }) => (allowHovering ? hoverStyles : null)};

    &:focus {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.palette.borderDark};
        outline: none;
    }

    ${({ isToday, dayStatus }) => (isToday && dayStatus !== DayStatus.FADED ? todayStyle : null)};
    ${({ focused }) => focused && focusedStyle};
    ${({ dayStatus }) => dayStyles[dayStatus]};

    ${({ selected, faded }) => selected && !faded && selectedStyle};
`;

const DateStyled = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: inherit;
    z-index: 1; /* TODO: Need a strategy for handling z-index responsibly */
`;

export { TdStyled, DateStyled };
