/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const CalendarHeader = styled.tr`
    display: ${({ mobileHeaders }) => (mobileHeaders ? 'table-row' : 'none')};
    user-select: none;

    @media screen and (min-width: 720px) {
        display: table-row;
    }
`;

const MonthHeader = styled.caption`
    position: relative;

    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    line-height: 1.5;

    user-select: none;
    margin-bottom: ${({ theme }) => theme.spacing[1]};
`;

const TableStyled = styled.table`
    border-collapse: separate;
    border-spacing: 0 ${({ theme }) => theme.spacing[1]};

    display: flex;
    flex-direction: column;
`;

export { MonthHeader, CalendarHeader, TableStyled };
