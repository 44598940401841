/* eslint-disable import/prefer-default-export */
const DayStatus = Object.freeze({
    FADED: 'faded', // For when a date 'overflows' into another month or is in the past
    HIGHLIGHTED: 'highlighted',
    SELECTED_FROM: 'selected-from',
    SELECTED_SOLO: 'selected-solo',
    SELECTED_TO: 'selected-to',
    SELECTED: 'selected',
    UNAVAILABLE_FADED: 'unavailable-faded',
    UNAVAILABLE: 'unavailable',
    UNSELECTED: 'unselected',
    AVAILABLE_FROM: 'available-from',
    AVAILABLE_TO: 'available-to',
    AVAILABLE: 'available',
});

export { DayStatus };
