import React from 'react';
import Week from '../Week';
import { getMonth } from '../../calendarConstructor';

const Month = ({
    onDayClick,
    onDayHover,
    focusedDate,
    month,
    selectedRange,
    highlightedRange,
    unavailableDates,
    availableDates,
    allowHovering,
    allowSelecting,
    showAvailability,
}) => (
    <tbody>
        {getMonth(month, {
            selectedRange,
            highlightedRange,
            unavailableDates,
            availableDates,
            showAvailability,
        }).map((week) => (
            <Week
                focusedDate={focusedDate}
                week={week}
                onDayHover={onDayHover}
                onDayClick={onDayClick}
                key={week[0].date.getTime()}
                allowHovering={allowHovering}
                allowSelecting={allowSelecting}
            />
        ))}
    </tbody>
);

Month.defaultProps = {
    focusedDate: null,
    highlightedRange: { dateFrom: null, dateTo: null },
    month: new Date(),
    onDayClick: () => {},
    onDayHover: () => {},
    selectedRange: { dateFrom: null, dateTo: null },
    unavailableDates: [],
};

export default Month;
