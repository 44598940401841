import React from 'react';
import { isSameDay, isBefore, isAfter } from 'date-fns';
import { parseDate } from 'api/helpers/format/date';

// Relative imports
import Day from '../Day';

const Week = ({ week, onDayClick, onDayHover, focusedDate, allowHovering, allowSelecting }) => (
    <tr>
        {week.map((day) => {
            const { dayStatus, date, isToday, isOverflow } = day;
            const today = new Date();
            const focusedDateParsed = parseDate(focusedDate);

            const isFocused =
                (isSameDay(day.date, focusedDateParsed) &&
                    isBefore(day.date, today) &&
                    !isOverflow) ||
                (isSameDay(day.date, focusedDateParsed) && isToday && !isOverflow) ||
                (isSameDay(day.date, focusedDateParsed) && isAfter(day.date, today) && !isOverflow);

            // If the day is in focus we set it's tab-index to 0 so that it can be re-focused when returning.
            const tabIndex = isFocused ? 0 : -1;

            return (
                <Day
                    date={date}
                    isOverflow={isOverflow}
                    dayStatus={dayStatus}
                    isFocused={allowSelecting ? isFocused : false}
                    key={date.getTime()}
                    onDayClick={onDayClick}
                    onDayHover={onDayHover}
                    tabIndex={allowSelecting ? tabIndex : -1}
                    isToday={isToday}
                    allowHovering={allowHovering}
                />
            );
        })}
    </tr>
);

export default Week;
