import styled from 'styled-components';
import { zIndex } from 'components/ZIndexManager';
import { ButtonIcon } from 'components/buttons';
import { smallTextStyles } from 'typography';
import Calendar from './components/Calendar';

const WrapperStyled = styled.div`
    position: relative;
    display: flex;

    color: ${({ theme: { palette } }) => palette.text};
    flex-direction: column;

    @media screen and (min-width: 720px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const CalendarStyled = styled(Calendar)`
    margin-bottom: ${({ theme }) => theme.spacing[4]};

    &:last-of-type {
        margin-bottom: 0;
    }

    @media screen and (min-width: 720px) {
        margin-right: ${({ theme }) => theme.spacing[3]};
        margin-bottom: 0;
        &:last-of-type {
            margin-right: 0;
        }
    }
`;

const NavButton = styled(ButtonIcon)`
    display: ${({ mobilePagination }) => (mobilePagination ? 'inline' : 'none')};
    background: none;
    &:hover:enabled,
    &:active:enabled {
        background: none;
    }
    padding: 0;

    & svg {
        fill: ${({ theme }) => theme.palette.grey[600]};
    }
    z-index: ${zIndex};

    @media screen and (min-width: 720px) {
        display: inline;
    }
`;

const BackButtonStyled = styled(NavButton)`
    position: absolute;
    left: 0;
`;

const ForwardButtonStyled = styled(NavButton)`
    position: absolute;
    right: 0;
`;

const KeyContainerStyled = styled.ul`
    margin: ${({ theme }) => theme.spacing[1]} 0;
    padding-left: ${({ theme }) =>
        theme.spacing[5]}; // aligned with KeyItemStyled::before margin-left
    list-style: none;
    ${smallTextStyles};
`;

const KeyItemStyled = styled.li`
    margin: 0;

    ::before {
        content: '';
        background-color: ${({ theme }) => theme.palette.primarySoft};
        display: inline-block;
        vertical-align: bottom;
        width: ${({ theme }) => theme.spacing[3]};
        height: ${({ theme }) => theme.spacing[3]};
        border-radius: 50%;
        margin-left: -${({ theme }) => theme.spacing[5]}; // width + margin-right
        margin-right: ${({ theme }) => theme.spacing[2]};
    }
`;

export {
    WrapperStyled,
    BackButtonStyled,
    ForwardButtonStyled,
    CalendarStyled,
    KeyContainerStyled,
    KeyItemStyled,
};
