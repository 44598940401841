/* eslint-disable import/prefer-default-export */
import {
    addDays,
    isMonday,
    isPast,
    isSameDay,
    isSameMonth,
    isSunday,
    isToday,
    isValid,
    isWithinInterval,
    startOfMonth,
    subDays,
} from 'date-fns';
import { parseDate } from 'api/helpers/format/date';
import { DayStatus } from './components/Day';

const isDayUnavailable = (day, unavailableDates) => {
    if (!unavailableDates) return false;

    const unavailable = unavailableDates.some((dateRange) => {
        const { dateFrom, dateTo } = dateRange;
        const dateFromParsed = parseDate(dateFrom);
        const dateToParsed = parseDate(dateTo);

        return (
            isValid(dateFromParsed) &&
            isValid(dateToParsed) &&
            isWithinInterval(day, { start: dateFromParsed, end: dateToParsed })
        );
    });

    return unavailable;
};

const isDayAvailable = (day, availableDates) => {
    if (!availableDates) return false;

    const available = availableDates.some((dateRange) => {
        const { dateFrom, dateTo } = dateRange;
        const dateFromParsed = parseDate(dateFrom);
        const dateToParsed = parseDate(dateTo);

        return isWithinInterval(day, { start: dateFromParsed, end: dateToParsed });
    });

    return available;
};

const getAvailablePositionStatus = (day, availableDates) => {
    let status = DayStatus.AVAILABLE;
    availableDates.some((dateRange) => {
        const { dateFrom, dateTo } = dateRange;
        const dateFromParsed = parseDate(dateFrom);
        const dateToParsed = parseDate(dateTo);

        // if start of an availableRange = DayStatus.AVAILABLE_FROM
        if (isSameDay(day, dateFromParsed)) {
            status = DayStatus.AVAILABLE_FROM;
            return true;
        }
        // if end of an availableRange = DayStatus.AVAILABLE_TO
        if (isSameDay(day, dateToParsed)) {
            status = DayStatus.AVAILABLE_TO;
            return true;
        }
        return false;
    });

    return status;
};

// Day is selected if it's the same as the current dateFrom
// or if it is within the selectedRange
const isDaySelected = (day, selectedRange) => {
    const { dateFrom, dateTo } = selectedRange;
    const dateFromParsed = parseDate(dateFrom);
    const dateToParsed = parseDate(dateTo);

    if (isValid(dateFromParsed) && isValid(dateToParsed)) {
        return (
            isWithinInterval(day, { start: dateFromParsed, end: dateToParsed }) ||
            isSameDay(day, dateFromParsed) ||
            isSameDay(day, dateToParsed)
        );
    }

    return isSameDay(day, dateFromParsed);
};

const isDayHighlighted = (day, highlightedRange) => {
    const { dateFrom, dateTo } = highlightedRange;
    const dateFromParsed = parseDate(dateFrom);
    const dateToParsed = parseDate(dateTo);

    if (isValid(dateFromParsed) && isValid(dateToParsed)) {
        return isWithinInterval(day, { start: dateFromParsed, end: dateToParsed });
    }

    return isSameDay(day, dateFromParsed);
};

const getDayStatus = (day, options) => {
    const {
        selectedRange,
        availableDates,
        unavailableDates,
        highlightedRange,
        month,
        showAvailability,
    } = options;

    const selectedRangeParsed = {
        dateFrom: parseDate(selectedRange.dateFrom),
        dateTo: parseDate(selectedRange.dateTo),
    };
    const faded = !isSameMonth(day, month) || (isPast(day) && !isToday(day));
    const unavailable = isDayUnavailable(day, unavailableDates);
    const available = showAvailability && isDayAvailable(day, availableDates);
    const selected = isDaySelected(day, selectedRangeParsed);
    const highlighted = isDayHighlighted(day, highlightedRange);
    const dateFrom = isSameDay(selectedRangeParsed.dateFrom, day);
    const dateTo = isSameDay(selectedRangeParsed.dateTo, day);

    if (unavailable && faded) {
        return DayStatus.UNAVAILABLE_FADED;
    }
    if (selected && !unavailable && !faded) {
        if (isSameDay(dateFrom, highlightedRange.dateTo)) {
            return DayStatus.SELECTED_SOLO;
        }
        if (dateFrom && highlightedRange.dateTo) {
            return DayStatus.SELECTED_FROM;
        }
        if (selectedRangeParsed.dateFrom && !selectedRangeParsed.dateTo) {
            return DayStatus.SELECTED_SOLO;
        }
        if (
            selectedRangeParsed.dateFrom &&
            selectedRangeParsed.dateTo &&
            isSameDay(selectedRangeParsed.dateFrom, selectedRangeParsed.dateTo)
        ) {
            return DayStatus.SELECTED_SOLO;
        }
        if (dateFrom) {
            return DayStatus.SELECTED_FROM;
        }
        if (dateTo) {
            return DayStatus.SELECTED_TO;
        }
        return DayStatus.SELECTED;
    }
    if (highlighted && !unavailable && !faded) {
        // TODO: replace with highlight specific styling
        // Currently we borrow the selected status for styling
        // if (dateFrom) {
        //     return DayStatus.SELECTED_FROM;
        // }
        return DayStatus.HIGHLIGHTED;
        // TODO: handle HIGHLIGHTED_FROM and HIGHLIGHTED_TO
    }
    if (faded) {
        return DayStatus.FADED;
    }
    if (unavailable) {
        return DayStatus.UNAVAILABLE;
    }
    if (available) {
        return getAvailablePositionStatus(day, availableDates);
    }
    return DayStatus.UNSELECTED;
};

const getMonday = (date) => {
    // If the day is a monday return it
    if (isMonday(date)) {
        return date;
    }
    // otherwise get the previous day
    const newDate = subDays(date, 1);
    // and check if it's monday
    return getMonday(newDate);
};

const getNextSunday = (date) => {
    if (isSunday(date)) {
        return date;
    }

    const newDate = addDays(date, 1);

    return getNextSunday(newDate);
};

const getDaysInWeek = (startDate, options) => {
    const week = [];

    for (let dayIndex = 0; dayIndex < 7; dayIndex += 1) {
        const day = addDays(startDate, dayIndex);
        const isOverflow = !isSameMonth(day, options.month);
        week.push({
            isOverflow,
            dayStatus: getDayStatus(day, options),
            isToday: isToday(day),
            // TODO: work out if it's better to return an iso string here
            // in terms of memory efficiency/performance
            date: day,
        });
    }
    return week;
};

const getWeeksInMonth = (startDate, options) => {
    const weeks = [];
    // We currently just harcode 6 weeks to keep consistency between the two months
    const weeksInMonth = 6;
    for (let dayIndex = 0; dayIndex < weeksInMonth; dayIndex += 1) {
        // There are 7 days in a week,
        // Multiplying the current week by 7 will get how many days into the month the given week starts
        const weekStart = addDays(startDate, dayIndex * 7);
        weeks.push(getDaysInWeek(weekStart, options));
    }

    return weeks;
};

const getMonth = (
    month,
    options = {
        highlightedRange: { dateFrom: null, dateTo: null },
        selectedRange: { dateFrom: null, dateTo: null },
        unavailableDates: [],
        availableDates: [],
    }
) => {
    const optionsWithMonth = {
        ...options,
        month,
    };
    const startMonth = startOfMonth(month);
    // All weeks should start on a monday, so the month should start on a monday too
    const firstMonday = getMonday(startMonth);
    return getWeeksInMonth(firstMonday, optionsWithMonth);
};

export {
    getMonth,
    getWeeksInMonth,
    getDaysInWeek,
    getNextSunday,
    getMonday,
    getDayStatus,
    isDayHighlighted,
    isDaySelected,
    isDayUnavailable,
};
